import useAsyncFetch from "./useAsyncFetch";

const useGetPageCollectionsData = () =>
	useAsyncFetch({
		path: "/rest/page/collections/",
		method: "get",
		options: {
			cached: true,
			transform: (data) => data.payload?.collections ?? []
		}
	});

export default useGetPageCollectionsData;
